import createReactComponent from '../createReactComponent';
export default createReactComponent('filter-off', 'IconFilterOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 5h9.5a1 1 0 0 1 .5 1.5l-4.049 4.454m-.951 3.046v5l-4 -3v-4l-5 -5.5a1 1 0 0 1 .18 -1.316',
      key: 'svg-1',
    },
  ],
]);
