import createReactComponent from '../createReactComponent';
export default createReactComponent('filter', 'IconFilter', [
  [
    'path',
    {
      d: 'M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5',
      key: 'svg-0',
    },
  ],
]);
